<template>
  <div class="properties section">
    <navigation></navigation>
    <v-row class="pa-5 mt-8 maps-group">
      <v-col cols="12" md='4'>
        <div class="property_1">
          <a href="https://sandbox.wbagadion.com/lafinca_interactive_map/" style="text-decoration:none;">
          <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3420.1598526873663!2d122.9704722424199!3d13.791412803572438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a21b6c2380c849%3A0x586dbb63fc8df670!2sAlteza%2C%20Sipocot%2C%20Camarines%20Sur!5e1!3m2!1sen!2sph!4v1659120829406!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">La Finca Alteza</v-card-title>
          </v-card>
          </a>
        </div>
      </v-col>
      <v-col cols="12" md='4'>
        <div class="property_2">
          <!-- <router-link to="/"> -->
          <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55324.851035425374!2d123.37008337061474!3d13.656955286122994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1ebbe6f36bc91%3A0xcf18fd32314af505!2sMount%20Isarog!5e1!3m2!1sen!2sph!4v1659121034575!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">Lorem Ipsum</v-card-title>
          </v-card>
          <!-- </router-link> -->
        </div>
      </v-col>
      <v-col cols="12" md='4'>
        <div class="property_3">
          <!-- <router-link to="/"> -->
            <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55324.851035425374!2d123.37008337061474!3d13.656955286122994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1ebbe6f36bc91%3A0xcf18fd32314af505!2sMount%20Isarog!5e1!3m2!1sen!2sph!4v1659121034575!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">Lorem Ipsum</v-card-title>
          </v-card>
          <!-- </router-link> -->
        </div>
      </v-col>
      <v-col cols="12" md='4'>
        <div class="property_4">
          <!-- <router-link to="/"> -->
            <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55324.851035425374!2d123.37008337061474!3d13.656955286122994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1ebbe6f36bc91%3A0xcf18fd32314af505!2sMount%20Isarog!5e1!3m2!1sen!2sph!4v1659121034575!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">Lorem Ipsum</v-card-title>
          </v-card>
          <!-- </router-link> -->
        </div>
      </v-col>
      <v-col cols="12" md='4'>
        <div class="property_4">
          <!-- <router-link to="/"> -->
            <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55324.851035425374!2d123.37008337061474!3d13.656955286122994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1ebbe6f36bc91%3A0xcf18fd32314af505!2sMount%20Isarog!5e1!3m2!1sen!2sph!4v1659121034575!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">Lorem Ipsum</v-card-title>
          </v-card>
          <!-- </router-link> -->
        </div>
      </v-col>
      <v-col cols="12" md='4'>
        <div class="property_4">
          <!-- <router-link to="/"> -->
            <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55324.851035425374!2d123.37008337061474!3d13.656955286122994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1ebbe6f36bc91%3A0xcf18fd32314af505!2sMount%20Isarog!5e1!3m2!1sen!2sph!4v1659121034575!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">Lorem Ipsum</v-card-title>
          </v-card>
          <!-- </router-link> -->
        </div>
      </v-col>
      <v-col cols="12" md='4'>
        <div class="property_4">
          <!-- <router-link to="/"> -->
            <v-card>
            <v-card-text>
                <v-sheet>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55324.851035425374!2d123.37008337061474!3d13.656955286122994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1ebbe6f36bc91%3A0xcf18fd32314af505!2sMount%20Isarog!5e1!3m2!1sen!2sph!4v1659121034575!5m2!1sen!2sph"
                    allowfullscreen
                    frameborder="0"
                    height="250"
                    style="border:0"
                    width="100%"
                  />
                </v-sheet>
            </v-card-text>
            
            <v-card-title class="text-center">Lorem Ipsum</v-card-title>
          </v-card>
          <!-- </router-link> -->
        </div>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  import Navigation from './Navigation.vue';
  export default {
    components: { Navigation, }
  }

</script>

<style>
  .section{padding: 5px 65px 5px 65px;}
  @media only screen and (max-width: 600px) {
    .section{
      padding: 0px;
    }
    .maps-group{
      width: 100%;
    }
  }
</style>